.upload-block {
  background: $grey-lighter;
  border: 2px dashed $primary-color;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 16px;
  padding: 32px;
  position: relative;
  text-align: center;

  &-title {
    @include font-family($main-font-heavy);
    @include font-size(14);
    @include absolute-centered();
    color: $primary-color;
    letter-spacing: 1px;
    line-height: 2rem;
    text-transform: uppercase;
    width: calc(100% - 24px);
  }

  &-preview {
    left: 7px;
    position: absolute;
    top: 7px;
    width: calc(100% - 14px);

    &-img {
      width: 100%;
    }
  }

  &-print {
    min-height: 804px;
    overflow: hidden;
  }
}
