.footer {
  $footer: &;
  background: $primary-color;
  color: $white;
  padding: 40px 40px 32px;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-column {
    display: none;
    flex: 2;

    @include responsive(t-xs) {
      display: block;
    }
  }

  &-title {
    @include font-family($main-font-black);
    @include font-size(16);
    letter-spacing: 0;
    line-height: 3.2rem;
  }

  &-link {
    @include font-size(16);
    @include font-family($main-font-regular);
    color: $white;
    display: block;
    line-height: 3.2rem;

    &:hover,
    &:focus,
    &:active,
    &:focus:active {
      color: $white;
      text-decoration: underline;
    }
  }

  &-socials-media {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}
