.world {
  position: relative;
  text-align: center;

  &-content {
    left: 50%;
    max-width: 260px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;

    @include responsive(t-xs) {
      max-width: 530px;
    }
  }

  &-title {
    @include font-family($main-font-black);
    @include font-size(16);
    color: $primary-color;
    letter-spacing: 0;
    line-height: 2rem;
    margin-bottom: 24px;

    @include responsive(t-xs) {
      @include font-size(28);
      line-height: 3.6rem;
    }
  }

  .full-img-wrapper {
    height: 300px;

    @include responsive(t-xs) {
      height: 600px;
    }
  }
}
