.testimonial {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;

  &-content {
    flex: 1 0 100%;
    margin-right: 50px;

    .btn {
      display: block;
      max-width: 160px;

      @include responsive(t-xs) {
        max-width: 220px;
        margin: 0 0 24px;
      }
    }

    @include responsive(t-xs) {
      flex: 1;
      margin-right: 50%;
      order: initial;
      padding-right: 50px;
      width: 50%;
    }

    @include responsive(t) {
      padding-right: 100px;
    }
  }

  &-img {
    height: 100%;
    object-fit: cover;
    width: 100%;

    &-wrapper {
      margin: 0 auto 16px;

      @include responsive(t-xs) {
        flex: 1;
        height: 407px;
        margin: inherit;
        max-width: 460px;
        order: 3;
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
      }
    }
  }

  &-text {
    @include font-family($main-font-bold);
    @include font-size(24);
    color: $grey-darker;
    letter-spacing: 0;
    line-height: 3.2rem;
    margin-bottom: 32px;

    @include responsive(t-xs) {
      margin-bottom: 16px;
    }
  }

  .text-main {
    margin-bottom: 16px;
  }
}
