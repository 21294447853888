.thumb {
  $scope: &;
  background: $grey-light;
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
  height: 70px;
  width: 70px;

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto -20px;
    text-align: center;
  }

  &-add {
    border: 2px dashed $primary-color;
    cursor: pointer;

    &::after {
      @include absolute-centered();
      @include svg-bg('ic_plus');
      @include img-size(24px, 24px);
      content: '';
      display: block;
    }
  }

  &-filled {
    overflow: hidden;
    position: static;

    #{$scope} {
      &-img {
        display: block;
        width: 100%;
      }
    }
  }

  &:last-of-type {
    margin-right: 0;
  }

  &-delete {
    @include circle(22px);
    background: $negative;
    border: 1px solid $white;
    cursor: pointer;
    display: block;
    position: absolute;
    transform: translate(-8px, -78px);
  }

  &-content {
    position: relative;
  }

  .radio-button {
    position: absolute;
    right: -23px;
    top: 12px;

    .checkmark,
    .icon-check {
      height: 18px;
      width: 18px;
    }
  }
}
