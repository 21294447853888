.header {
  $scope: &;

  &-login {
    #{$scope} {
      &-left {
        display: none;
      }

      &-right {
        display: none;

        .text-info,
        .btn-primary {
          display: inline-block;
          vertical-align: middle;
        }

        .btn-primary {
          margin-left: 24px;
          min-width: 160px;
        }

        @include responsive(t) {
          display: block;
        }
      }
    }

    @include responsive(t) {
      justify-content: flex-end;
    }
  }
}
