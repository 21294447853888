@mixin svg-bg($name, $format: '@2x', $extension: png, $color: transparent, $position: 0 0, $repeat: no-repeat) {
  background: url(#{$doc-general-img-path}#{$name}#{$format}.#{$extension}) $color $position $repeat;
  background: none, url(#{$doc-general-img-path}#{$name}.svg) $color $position $repeat;
  .ie8 &, .androidbrowser & {
    @include ie8-bg($name, '', $extension, $color, $position, $repeat);
  }
}

@mixin png-bg($name, $format: '@2x', $extension: png, $color: transparent, $position: 0 0, $repeat: no-repeat) {
  background: url(#{$doc-general-img-path}#{$name}#{$format}.#{$extension}) $color $position $repeat;
  .ie8 &, .androidbrowser & {
    @include ie8-bg($name, '', $extension, $color, $position, $repeat);
  }
}

@mixin ie8-bg($name, $format: '@1x', $extension: png, $color: transparent, $position: 0 0, $repeat: no-repeat) {
  background: url(#{$doc-general-img-path}#{$name}#{$format}.#{$extension}) $color $position $repeat;
}

@mixin img-size($width, $height) {
  background-size: 100%;
  height: $height;
  width: $width;
}
