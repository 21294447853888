.station {
  $station: &;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $shadow;
  color: $grey-darker;
  display: block;
  letter-spacing: 0;
  margin-bottom: 10px;
  padding:  16px 46px 16px 24px;
  position: relative;

  @include responsive(t-xs) {
    display: flex;
    flex-wrap: wrap;
    padding:  20px 84px 20px 24px;
  }

  &-name,
  &-hardware,
  &-state,
  &-power {
    @include responsive(t-xs) {
      flex: 0 1 calc(50% - 12px);
    }
  }

  &-name,
  &-hardware {
    margin-bottom: 10px;

    &-title {
      @include font-family($main-font-regular);
      @include font-size(12);
      color: $grey-dark;
      line-height: 1.8rem;
    }

    &-content {
      @include font-family($main-font-bold);
      @include font-size(18);
      color: $grey-darker;
      line-height: 2.4rem;
    }

    @include responsive(t-xs) {
      margin-bottom: 16px;
    }
  }

  &-state {
    margin-bottom: 10px;

    @include responsive(t-xs) {
      margin-bottom: 0;
    }
  }

  &-name,
  &-state {
    @include responsive(t-xs) {
      margin-right: 24px;
    }
  }

  &-edit {
    display: none;
  }

  .icon-options {
    position: absolute;
    right: 16px;
    top: 24px;

    @include responsive(t-xs) {
      right: 24px;
    }
  }

  &.edit {
    padding:  14px 24px 18px;

    #{$station} {
      &-edit {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        width: 100%;

        .form-input {
          @include responsive(s-xl) {
            margin-bottom: 0;
            margin-right: 24px;
          }
        }

        .btn {
          @include responsive(s-xl) {
            flex: none;
          }
        }

        @include responsive(s-xl) {
          align-items: center;
          flex-direction: row;
        }
      }

      &-name,
      &-hardware,
      &-state,
      &-power {
        display: none;
      }
    }

    .icon-options {
      display: none;
    }
  }
}
