.toggle {
  $scope: &;

  &-label,
  &-control {
    vertical-align: middle;
  }

  &-label {
    @include font-family($main-font-bold);
    @include font-size(10);
    color: $grey;
    letter-spacing: 0;
    margin-right: 14px;
    transition: color .25s cubic-bezier(.73, .31, 1, .9);
  }

  &-control {
    background: $grey-light;
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    height: 12px;
    margin: 0;
    position: relative;
    transition: background .25s cubic-bezier(.73, .31, 1, .9);
    width: 32px;

    &::before {
      @include circle(24px);
      background: $grey;
      content: '';
      left: 0;
      position: absolute;
      top: 50%;
      transform: translate(-6px, -50%);
      transition: all .25s cubic-bezier(.73, .31, 1, .9);
    }
  }

  &-input {
    display: none;

    &:checked {
      ~ #{$scope} {
        &-label {
          color: $primary-color;
        }

        &-control {
          background: $primary-transparent-color;

          &::before {
            background: $primary-color;
            transform: translate(14px, -50%);
          }
        }
      }
    }
  }

  &-highlighted {
    #{$scope} {
      &-label {
        @include font-family($main-font-heavy);
        @include font-size(16);
      }
    }
  }
}
