.modal-hours,
.modal-scheduler {
  .form-input {
    display: inline-block;
    vertical-align: middle;
    width: calc(50% - 15px);

    @include responsive(t-xs) {
      width: 140px;
    }

    &-price {
      width: 100%;
    }
  }

  &-body {
    .form-input-hours {
      + .text-secondary {
        margin: -4px 0 24px;
      }
    }

    .text-secondary {
      margin: 0 0 24px;
    }
  }

  @include responsive(s-xl) {
    bottom: unset;
    top: 250px;
  }
}
