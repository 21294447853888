%text-main {
  color: $grey-darker;
  letter-spacing: 0;
  line-height: 2.4rem;
  margin-bottom: 16px;
}

%text-secondary {
  @include font-size(12);
  color: $grey-darker;
  line-height: 1.6rem;
  letter-spacing: 0;
  margin-bottom: 12px;
}

.text-main {
  @extend %text-main;
}

.text-secondary {
  @extend %text-secondary;

  span {
    @include font-family($main-font-heavy);

    &.text-highlight {
      @include font-family($main-font-bold);
      color: $primary-color;
    }
  }
}

.text-legend {
  @extend %text-secondary;
  color: $grey;

  .text-highlight {
    @include font-family($main-font-bold);
  }

  .icon-download-small {
    margin-left: 4px;
    vertical-align: middle;
  }

  + .form-block-title-section {
    margin-top: 36px;
  }
}

.text-link {
  cursor: pointer;
  text-decoration: underline;
}
