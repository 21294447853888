.hori-scheduler {
  $self: &;
  margin-bottom: 24px;
  position: relative;

  &-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &-item {
      @include font-size(10);
      color: $grey-dark;
      flex: 1;
      min-height: 24px;
      padding-bottom: 10px;
      position: relative;

      span {
        display: flex;
        justify-content: center;
      }

      &.hide-on-mobile {
        span {
          display: none;

          @include responsive(t-xs) {
            display: flex;
          }
        }
      }

      &::after {
        background: $grey-light;
        bottom: 0;
        content: '';
        display: flex;
        height: 10px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 1px;
      }
    }
  }

  &-title {
    @include font-family($main-font-heavy);
    @include font-size(12);
  }

  &-background {
    background: $grey-light;
    height: 64px;
    margin: 0 auto;
    padding: 4px;
    width: calc((100% - (100% / 24)) + 2px);

    #{$self} {
      &-title {
        color: $grey;
      }
    }
  }

  &-content {
    bottom: 0;
    display: flex;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: calc((100% - (100% / 24)) + 2px);
  }

  &-time-slot {
    cursor: pointer;
    height: 64px;
    padding: 4px;
    position: relative;
    width: calc(100% / 24);

    &-filled {
      background: $green;
      border-radius: 4px;

      #{$self} {
        &-title {
          color: $white;
          overflow: hidden;
        }
      }
    }

    &-filling {
      background: $white;
      border: 2px solid $green;
      border-radius: 4px;
    }
  }
}
