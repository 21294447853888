.list-faq-item {
  background: $white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $shadow;
  margin-bottom: 10px;
  padding:  16px 24px;
  position: relative;

  &-title {
    @include font-family($main-font-bold);
    @include font-size(16);
    color: $grey-darker;
    letter-spacing: 0;
    line-height: 2.2rem;
    max-width: 68%;
  }

  &-actions {
    @include font-family($main-font-heavy);
    @include font-size(12);
    color: $primary-color;
    display: inline-block;
    letter-spacing: 0;
    line-height: 2.4rem;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);

    &:hover {
      color: $primary-color;
    }
  }
}
