%transi-move {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity .8s ease-in-out, transform .8s ease-in-out;

  &.animated {
    opacity: 1;
    transform: translateY(0);
  }
}

%transi-zoom {
  opacity: 0;
  transform: scale(.95);
  transition: opacity .8s ease-in-out, transform .8s ease-in-out;

  &.animated {
    opacity: 1;
    transform: scale(1);
  }
}

%transi-side-left {
  opacity: 0;
  transform: translateX(-20%);
  transition: opacity .8s ease-in-out, transform .8s ease-in-out;

  &.animated {
    opacity: 1;
    transform: translateX(0);
  }
}

%transi-side-right {
  opacity: 0;
  transform: translateX(20%);
  transition: opacity .8s ease-in-out, transform .8s ease-in-out;

  &.animated {
    opacity: 1;
    transform: translateX(0);
  }
}

.transi-move {
  @extend %transi-move;
}

.transi-zoom {
  @extend %transi-zoom;
}

.transi-side-left {
  @extend %transi-side-left;
}

.transi-side-right {
  @extend %transi-side-right;
}

.intro-transi-move {
  @extend %transi-move;
}

.intro-transi-zoom {
  @extend %transi-zoom;
}

.intro-transi-side-left {
  @extend %transi-side-left;
}
