.login {
  max-height: calc(100vh - 98px);
  overflow: hidden;

  .form-block-wrapper {
    margin: 0 auto;
    max-width: 300px;

    .btn-fixed {
      display: block;
      margin: 0 auto 16px;
    }
  }

  .form-block {
    margin-bottom: 32px;

    &-action {
      text-align: center;
    }

    &-message {
      margin-bottom: 16px;
      padding: 0 14px;
      text-align: center;
    }
  }

  .form-block-title {
    line-height: 1.4rem;
    text-align: center;
  }

  .illustration-city {
    left: calc(50% + 105px);
    margin-top: 32px;
    position: relative;
    transform: translateX(-50%);

    @include responsive(t-xs) {
      left: 50%;
      margin-top: 110px;
    }
  }
}
