.calendar {
  &-popper {
    background: $white;
    height: 100vh;
    left: 0;
    opacity: 0;
    overflow: hidden;
    max-height: 0;
    max-width: 0;
    padding: 64px $grid-gutter-width;
    position: fixed;
    top: -101vh;
    transform: translateY(0);
    transition: opacity 0s $cubic-bezier .4s, max-height .4s $cubic-bezier .4s, max-width .4s $cubic-bezier .4s, transform .4s $cubic-bezier;
    width: 100vw;
    z-index: map-get($zindex, lvl5);

    &-footer {
      display: flex;
      justify-content: space-between;
    }

    @include responsive(t-xs) {
      bottom: calc(48px + 16px);
      box-shadow: 0 46px 100px 0 $shadow;
      height: auto;
      left: 50%;
      padding: 14px;
      position: absolute;
      top: inherit;
      transform: translate(-50%, 0);
      transition: inherit;
      width: 320px;
    }

    &.show {
      max-height: 100vh;
      max-width: 100vw;
      opacity: 1;
      transform: translateY(101vh);
      transition: opacity 0s $cubic-bezier, max-height 0s $cubic-bezier, max-width 0s $cubic-bezier, transform .4s $cubic-bezier .1s;

      @include responsive(t-xs) {
        min-height: 486px;
        max-height: inherit;
        transform: translate(-50%, 0);
        transition: inherit;
      }
    }

    &.based-on-top {
      @include responsive(t-xs) {
        bottom: inherit;
        top: 48px;
      }
    }
  }
}
