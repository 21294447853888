.checkbox {
  $scopeCheckbox: &;
  @include font-size(12);
  color: $grey-darker;
  cursor: pointer;
  display: block;
  font-weight: inherit;
  letter-spacing: 0;
  line-height: 1.6rem;
  margin-bottom: 16px;
  padding-left: 48px;
  position: relative;

  input {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;

    &:checked {
      ~ .checkmark {
        background: $primary-color;
        border: 1px solid $white;

        .icon-check {
          display: inline-block;
        }
      }
    }
  }

  &-title {
    @include font-family($main-font-bold);
    color: $grey-darker;
    display: block;
    letter-spacing: 0;
    line-height: 1.6rem;
    margin-left: -12px;

    + #{$scopeCheckbox}-text {
      display: block;
      margin-left: -12px;
      margin-top: -2px;
    }
  }

  .checkmark {
    background: $white;
    border: 1px solid $grey-darker;
    border-radius: 4px;
    height: 24px;
    left: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all .25s ease;
    width: 24px;

    .icon-check {
      display: none;
      position: absolute;
      left: -1px;
      top: -1px;
    }
  }

  &-right {
    padding-left: 0;
    padding-right: 48px;
    text-align: right;

    .checkmark {
      left: inherit;
      right: 4px;
    }
  }

  &-with-icon {
    height: 96px;
    margin: 0 0 24px;
    padding: 8px 0 0;
    text-align: center;
    width: 100%;

    .checkmark {
      background: transparent;
      border: 2px solid $grey-light;
      border-radius: 8px;
      height: 100%;
      left: 0;
      position: absolute;
      transform: translateY(0);
      top: 0;
      width: 100%;
    }

    input {
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;

      &:checked {
        ~ .checkmark {
          background: transparent;
          border: 2px solid $primary-color;

          ~ #{$scopeCheckbox} {
            &-text {
              @include font-family($main-font-heavy);
              color: $primary-color;
            }
          }
        }
      }
    }

    #{$scopeCheckbox} {
      &-icon {
        @include img-size(48px, 48px);
        display: block;
        margin: 0 auto;
      }

      &-text {
        @include font-size(14);
        color: $grey;
        line-height: 3.2rem;
        transition: all .25s ease;
      }
    }
  }

  &-validate,
  &-cancel {
    #{$scopeCheckbox} {
      &-text {
        @include font-family($main-font-heavy);
      }
    }

    &.checkbox-with-icon {
      input {
        &:checked {
          ~ #{$scopeCheckbox} {
            &-text {
              color: $white;
              position: relative;
            }
          }
        }
      }
    }
  }

  &-validate {
    #{$scopeCheckbox} {
      &-text {
        color: $primary-color;
      }
    }

    .checkmark {
      border: 2px solid $primary-color;
    }

    input {
      &:checked {
        ~ .checkmark {
          background: $primary-color;
          border: 2px solid $primary-color;
        }

        ~ .icon-validate {
          @include svg-bg('ic_validate_white');
          position: relative;
        }
      }
    }
  }

  &-cancel {
    #{$scopeCheckbox} {
      &-text {
        color: $secondary-color;
      }
    }

    .checkmark {
      border: 2px solid $secondary-color;
    }

    input {
      &:checked {
        ~ .checkmark {
          background: $secondary-color;
          border: 2px solid $secondary-color;
        }

        ~ .icon-cancel {
          @include svg-bg('ic_refuse_white');
          position: relative;
        }
      }
    }
  }
}
