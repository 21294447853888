.calendar {
  $self: &;
  margin-bottom: 14px;

  &-header {
    &-section {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 8px;

      &-value {
        @include font-size(16);
        color: $black;
        line-height: 2.2rem;
      }

      &-month {
        #{$self}-header-section-value {
          @include font-family($main-font-bold);
          @include font-size(16);
        }
      }

      &-year {
        #{$self}-header-section-value {
          @include font-family($main-font-bold);
          @include font-size(16);
        }
      }
    }

    &-button {
      align-items: center;
      background: inherit;
      border: inherit;
      display: flex;
      justify-content: center;
      padding: 0;

      .icon-chevron {
        @include img-size(32px, 32px);
      }

      &-prev {
        transform: rotate(180deg);
      }

      &[disabled] {
        opacity: 0;
      }
    }
  }

  .react-datepicker {
    border-radius: 0;
    border: 0;
    display: flex;
    width: 100%;

    &__triangle {
      display: none;
    }

    &__month-container {
      width: 100%;
    }

    &__header {
      background: inherit;
      border: 0;
      padding: 0;
    }

    &__current-month {
      margin-bottom: 16px;
    }

    &__month {
      margin: 0;
    }

    &__day {
      @include font-family($main-font-medium);
      @include font-size(12);
      background: inherit;
      border-radius: 0;
      color: $black;
      line-height: 2rem;
      margin: 6px 0;
      padding: 8px 11px;
      position: relative;
      width: calc(100% / 7);
      z-index: map-get($zindex, lvl0);

      @include responsive(s-xxs) {
        padding: 11px;
      }

      &:hover {
        background: $primary-color;
        color: $white;
      }

      &--disabled {
        color: $grey-dark;

        &:hover {
          background: inherit;
          color: $grey-dark;
          cursor: default;
        }
      }

      &-name {
        margin: 0 0 6px 0;
        padding: 8px 11px;
        width: calc(100% / 7);

        &s {
          @include font-family($main-font-bold);
          @include font-size(12);
          color: $primary-color;
          line-height: 2rem;
        }

        @include responsive(s-xxs) {
          padding: 11px;
        }
      }

      &--in-range {
        background: $third-color;
        color: $white;
      }

      &--selected {
        background: inherit;
        color: $white;

        &::after {
          @include absolute-centered();
          @include circle(36px);
          content: '';
          background: $primary-color;
          z-index: map-get($zindex, lvl-1);

          @include responsive(s-xxs) {
            @include circle(42px);
          }
        }
      }

      &--range {
        &-start,
        &-end {
          background: inherit;
          color: $white;

          &:hover {
            background: $primary-color;

            &::before {
              display: none;
            }
          }

          &::before {
            background: $third-color;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 50%;
            z-index: map-get($zindex, lvl-1);
          }

          &::after {
            @include absolute-centered();
            @include circle(36px);
            content: '';
            background: $primary-color;
            z-index: map-get($zindex, lvl-1);

            @include responsive(s-xxs) {
              @include circle(42px);
            }
          }
        }

        &-start {
          &::before {
            right: 0;
          }
        }

        &-end {
          &::before {
            left: 0;
          }
        }
      }
    }
  }
}
