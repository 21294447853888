.menu {
  background: $white;
  border-bottom: 1px solid $grey-light;
  margin: 0 -10px 10px;

  &-tabs {
    position: relative;

    &-content {
      display: flex;
      justify-content: center;
      padding: 12px;
    }

    @include responsive(t-xs) {
      display: none;
    }
  }

  &-scrollable {
    overflow-x: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    position: relative;
    width: 100%;

    &::-webkit-scrollbar {
      background: transparent;
      display: none;
      height: 0 !important;
      width: 0 !important;
    }

    &-content {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      padding: 12px;

      @include responsive(t) {
        display: block;
      }
    }

    @include responsive(t) {
      overflow: inherit;
    }
  }

  &-indicator {
    border-radius: 2px 2px 0 0;
    background: $primary-color;
    bottom: 0;
    display: block;
    height: 4px;
    left: 18px;
    min-width: 10px;
    position: absolute;
    transition: width .4s ease-in-out;

    @include responsive(t) {
      display: none;
    }
  }

  &-item {
    $scope-menu-item: &;
    @include font-size(14);
    color: $grey;
    letter-spacing: 0;
    padding: 0 12px;
    text-decoration: none;
    transition: all .25s ease;
    white-space: nowrap;

    @include responsive(t) {
      @include font-size(18);
      border-radius: 4px;
      cursor: pointer;
      display: block;
      line-height: 3.2rem;
      margin-bottom: 20px;
      position: relative;
      padding: 8px 24px 8px 16px;
    }

    .icon-warning {
      @include img-size(20px, 20px);
      display: none;
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);

      @include responsive(t) {
        display: block;
      }
    }

    &:hover {
      color: $primary-color;
    }

    &:focus {
      color: $grey;
      outline: none;
      text-decoration: none;
    }

    &-number {
      @include circle(28px);
      @include font-family($main-font-heavy);
      @include font-size(12);
      background: $grey;
      color: $white;
      display: none;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;

      @include responsive(t) {
        display: block;
      }
    }

    &.current {
      @include font-family($main-font-bold);
      color: $primary-color;

      @include responsive(t) {
        @include font-family($main-font-heavy);
        background: $white;
      }

      #{$scope-menu-item}-number {
        background: $primary-color;
      }

      &.warning {
        @include responsive(t) {
          color: $secondary-color;
        }

        #{$scope-menu-item}-number {
          @include responsive(t) {
            background: $secondary-color;
          }
        }
      }
    }
  }

  &-wrapper {
    position: relative;
  }

  @include responsive(t) {
    background: transparent;
    border-bottom: 0;
    margin: 0;
  }
}

.scrolling {
  .menu {
    @include responsive(t) {
      left: auto;
      position: fixed;
      top:  96px;
      transform: translate3d(0, 0, 0);
      width: calc((960px / 3) - 20px);
    }
  }

  &.certification-validation {
    .menu {
      @include responsive(t) {
        top:  calc(96px + 84px + 24px);
      }
    }
  }
}
