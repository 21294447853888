@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@mixin font-face($name, $path, $exts: eot otf ttf svg, $weight: null, $style: null) {
  $src: null;

  $extmods: (
          eot: '?',
          svg: '#' + str-replace($name, ' ', '_')
  );

  $formats: (
          otf: 'opentype',
          ttf: 'truetype'
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $src: append($src, url(quote($path + '.' + $extmod)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin font-size-px($size-value) {
  font-size: $size-value * 1px; //For add an unity to a number we must multiple this number by 1 unity
}

@mixin font-size-rem($size-value) {
  font-size: ($size-value / 10) * 1rem;
}

@mixin font-size($size-value) {
  @include font-size-px($size-value);
  @include font-size-rem($size-value);
}

@mixin font-family($family, $second: '') {
  font-family: $family;
}

@mixin user-select($value: none) {
  -webkit-user-select: $value;  /* Chrome all / Safari all */
  -moz-user-select: $value;     /* Firefox all */
  -ms-user-select: $value;      /* IE 10+ */
  user-select: $value;
}
