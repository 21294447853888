.intro {
  &-title {
    @include font-family($main-font-black);
    @include font-size(24);
    color: $white;
    line-height: 2.8rem;
    margin-bottom: 20px;

    @include responsive(t-xs) {
      @include font-size(48);
      line-height: 5.6rem;
      margin-bottom: 64px;
    }
  }

  &-subtitle {
    @include font-family($main-font-black);
    @include font-size(14);
    color: $grey-darker;
    display: none;
    letter-spacing: .5px;
    margin-bottom: 10px;
    text-transform: uppercase;

    @include responsive(t) {
      display: block;
    }
  }

  .form-block {
    border-radius: 10px;
    padding: 24px;

    &-title {
      @include font-size(16);
      @include font-family($main-font-black);
      color: $primary-color;
      letter-spacing: 0;
      line-height: 2.8rem;
      text-transform: none;

      .text-highlight {
        @include font-size(12);
        color: $grey-darker;
        display: block;
        margin-top: 4px;
        letter-spacing: .4px;
        line-height: 1.6rem;
        text-transform: uppercase;

        @include responsive(t-xs) {
          @include font-size(16);
          @include font-family($main-font-bold);
          letter-spacing: inherit;
          line-height: 2.4rem;
          text-transform: inherit;
        }
      }

      @include responsive(t-xs) {
        @include font-size(28);
        color: $grey-darker;
        line-height: 2.4rem;
      }
    }
  }
}
