.form-input {
  $scopeInput: &;
  overflow: hidden;
  margin-bottom: 16px;
  position: relative;
  width: 100%;

  &-field {
    @include font-family($main-font-bold);
    @include font-size(18);
    @include placeholder($grey-darker);
    background: transparent;
    border: 0;
    border-radius: 0;
    color: $grey-darker;
    display: block;
    letter-spacing: 0;
    line-height: 2.4rem;
    padding: 16px 10px 8px 0;
    position: relative;
    width: 100%;

    &:focus {
      outline: none;
    }

    &:-webkit-autofill {
      background: $white !important;

      &:hover,
      &:focus,
      &:active {
        background: $white !important;
      }
    }
  }

  &-label {
    @include font-family($main-font-regular);
    @include font-size(14);
    bottom: 0;
    color: $grey-dark;
    font-weight: inherit;
    height: 100%;
    letter-spacing: 0;
    line-height: 2rem;
    left: 0;
    margin: 0;
    padding: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;

    &::before,
    &::after {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &::before {
      border-bottom: 2px solid $grey-light;
    }

    &::after {
      border-bottom: 2px solid $primary-color;
      transform: translate(-100%, 0);
      transition: all .4s cubic-bezier(.12, .01, 0, .99);
    }

    &-content {
      display: block;
      overflow-x: hidden;
      padding: 14px 0;
      text-overflow: ellipsis;
      transition: transform .4s cubic-bezier(.12, .01, 0, .99);
      transform-origin: left;
      white-space: nowrap;
      width: 100%;
    }
  }

  .icon-eye {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity .4s ease-in-out, visibility 0s ease .4s;
    visibility: hidden;
  }

  .icon-search {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.on-focus {
    #{$scopeInput}-label {
      &::after {
        transform: translate(0, 0);
      }

      &-content {
        transform: translate(0, -16px) scale(.8);
      }
    }

    &.has-error {
      #{$scopeInput} {
        &-label {
          &::after {
            border-bottom: 2px solid $error;
          }
        }

        &-field {
          color: $error;
        }
      }
    }

    .icon-eye {
      display: block;
      opacity: 1;
      transition: opacity .4s ease-in-out, visibility 0s ease;
      visibility: visible;
    }
  }

  &-hours {
    #{$scopeInput} {
      &-field {
        @include font-family($main-font-heavy);
        @include font-size(24);
        @include placeholder($third-color);
        color: $third-color;
        padding: 7px 10px;
      }
    }
  }

  &-price {
    #{$scopeInput} {
      &-field {
        @include font-family($main-font-heavy);
        @include font-size(24);
        @include placeholder($primary-color);
        color: $primary-color;
        padding: 21px 10px 7px;
      }

      &-label {
        &::after {
          transform: translate(-100%, 0);
        }

        &-content {
          @include font-family($main-font-heavy);
          color: $grey-darker;
          transform: translate(-10px, -18px) scale(.8);
          white-space: nowrap;
        }
      }
    }
  }

  &-separator {
    display: inline-block;
    margin: 0 8px;
    text-align: center;
    vertical-align: middle;

    &-text {
      @include font-family($main-font-heavy);
      @include font-size(12);
      color: $grey-darker;
    }
  }

  &.show-password {
    .icon-eye {
      @include svg-bg('ic_password_show');
    }
  }
}
