.pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 16px 0 0;

  &-btn {
    @include circle(36px);
    background: $primary-color;
    display: inline-flex;
    position: relative;
    cursor: pointer;

    .icon-arrow {
      @include svg-bg('ic_chevron');
      @include img-size(12px, 12px);
      @include absolute-centered();
    }

    &.disabled {
      background: #e0e0e0;
      pointer-events: none;
    }
  }

  &-next {
    .icon-arrow {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }

  &-nav {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 14px;

    &-item {
      @include font-family($main-font-bold);
      @include font-size(16);
      color: $black;
      line-height: 2.2rem;
      margin: 0 6px;
      min-width: 24px;
      position: relative;
      text-align: center;
      text-decoration: none;
      cursor: pointer;

      &::before {
        background: $black;
        bottom: -5px;
        content: '';
        display: flex;
        height: 2px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) scaleX(0);
        width: 12px;
      }

      &:hover {
        color: $primary-color;
        text-decoration: none;
      }

      &.current {
        &::before {
          background: $black;
          content: '';
          display: flex;
          transform: translateX(-50%) scaleX(1);
        }
      }
    }

    &-more {
      @include font-family($main-font-bold);
      @include font-size(16);
      color: $black;
      line-height: 2.2rem;
      margin: 0 6px;
      min-width: 24px;
      position: relative;
      text-align: center;
      text-decoration: none;
    }
  }
}
