@import
'../layout/home/section',
'../layout/home/intro',
'../layout/home/promo',
'../layout/home/world';

.home {
  padding: 0;

  .header {
    .title-main {
      @include font-size(20);
      line-height: 2.4rem;

      @include responsive(t-xs) {
        @include font-size(24);
        line-height: 2.8rem;
      }
    }

    .text-info {
      display: none;
      margin-top: 4px;
      vertical-align: middle;

      .text-highlight {
        @include font-size(24);
        @include font-family($main-font-black);
        color: $primary-color;
      }

      @include responsive(t) {
        display: inline-block;
      }
    }

    &-left {
      .text-info {
        line-height: 4.8rem;
        margin-top: 0;
      }
    }

    &-right {
      > a {
        @include responsive(t) {
          display: none;
        }
      }

      .btn {
        display: none;
        margin-left: 12px;

        @include responsive(t) {
          display: inline-block;
        }
      }

      .text-info {
        @include font-size(12);
        @include font-family($main-font-regular);
        color: $grey-darker;
        text-align: right;
        text-transform: none;
      }

      .text-highlight {
        margin: 0 12px;
      }
    }
  }

  .text-main {
    margin-bottom: 32px;

    + .text-main {
      .link-paragraph {
        display: block;
        margin-top: -16px;
      }
    }

    @include responsive(t-xs) {
      margin-bottom: 24px;
    }
  }

  &-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-item {
      flex: 0 0 100%;
      width: 50%;

      &:first-child {
        @include responsive(s) {
          padding-left: 0;
          padding-right: 10px;
        }

        @include responsive(t-xs) {
          padding-right: 10px;
        }
      }

      &:last-child {
        @include responsive(s) {
          padding-left: 0;
        }

        @include responsive(t-xs) {
          padding-left: 10px;
          padding-right: 0;
        }
      }

      &-icon {
        @include img-size(96px, 96px);
        display: block;
        margin-left: -16px;
      }

      @include responsive(s) {
        flex: 1 0 auto;
        padding: 0 0 0 10px;
      }

      @include responsive(t-xs) {
        flex: 1;
        padding: 0 10px;
        text-align: inherit;
        width: inherit;
      }
    }
  }
}
