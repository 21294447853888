.header {
  $scope: &;
  align-items: center;
  background: $white;
  border-bottom: 1px solid $grey-light;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 16px;
  position: fixed;
  top: 0;
  transform: translate3d(0, 0, 0);
  width: 100%;
  z-index: map_get($zindex, lvl3);

  &-left {
    .logo-main,
    .logo-secondary {
      float: left;
      margin: -8px 0;

      a {
        display: block;
      }
    }

    .logo-main {
      @include responsive(t) {
        margin: -8px 68px -8px 28px;
      }
    }

    .logo-secondary {
      @include responsive(t) {
        display: none;
      }
    }
  }

  &-center {
    .logo-main {
      margin: -8px 0;
    }

    @include responsive(t) {
      @include absolute-centered();
    }
  }

  .link {
    @include font-family($main-font-heavy);
    @include font-size(12);
    color: $primary-color;
    letter-spacing: .86px;

    &:hover {
      color: $primary-color;
      text-decoration: none;
    }
  }

  &-title {
    @include font-family($main-font-black);
    @include font-size(16);
    color: $grey-darker;
    letter-spacing: 1px;
    line-height: 1.8rem;
    text-align: center;
    text-transform: uppercase;
  }

  .link {
    letter-spacing: .86px;

    .icon-arrow-left {
      margin-right: 16px;
      vertical-align: middle;
    }

    &-text {
      line-height: 4.8rem;
      vertical-align: middle;
    }
  }

  .text-info {
    letter-spacing: .43px;
  }

  @include responsive(t) {
    min-height: 98px;
  }
}

.creation,
.validation {
  .header {
    &-left {
      .link {
        display: none;

        @include responsive(t) {
          display: inline-block;
        }
      }

      .logo-main {
        @include responsive(t) {
          display: none;
        }
      }
    }

    &-center {
      display: none;

      @include responsive(t) {
        display: block;
      }
    }
  }
}

.certification,
.faq,
.cgv {
  .header {
    &-left {
      .link {
        display: none;

        @include responsive(t) {
          display: inline-block;
        }
      }

      .logo-secondary {
         @include responsive(t) {
           display: none;
         }
       }
    }

    &-center {
      display: none;

      @include responsive(t) {
        display: block;
      }
    }

    &-right {
      display: flex;
      align-items: center;
    }

    .nav-main {
      @include responsive(t) {
        display: none;
      }
    }
  }
}
