.list-contact-item {
  margin-bottom: 16px;
  position: relative;

  &-title {
    @include font-family($main-font-heavy);
    @include font-size(16);
    color: $grey-darker;
    letter-spacing: 0;
    line-height: 2.2rem;
    margin-bottom: 12px;
    max-width: 59%;

    @include responsive(s) {
      margin-bottom: 0;
    }
  }

  &-actions {
    display: block;

    &-link {
      @include font-family($main-font-bold);
      @include font-size(12);
      color: $primary-color;
      display: inline-block;
      letter-spacing: 0;
      line-height: 2.4rem;
      margin-right: 24px;

      &:hover {
        color: $primary-color;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    @include responsive(s) {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
