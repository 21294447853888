.options {
  $options: &;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  &-title {
    @include font-family($main-font-heavy);
    @include font-size(16);
    color: $grey-darker;
    letter-spacing: 0;
  }

  .toggle-wrapper {
    margin-left: 16px;
    white-space: nowrap;
  }

  &-panel {
    background: $white;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 $shadow;
    opacity: 0;
    padding: 16px 24px;
    position: absolute;
    right: 0;
    top: 48px;
    transform: translateY(-20px);
    transition: all .4s ease-in-out;
    visibility: hidden;
    z-index: map-get($zindex, lvl0-1);

    &-list {
      &-item {
        @include font-size(14);
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 0;
        }

        &-action {
          color: $grey-darker;
          cursor: pointer;

          &.delete {
            color: $negative;

            &:hover {
              color: $negative;
            }
          }

          &:hover {
            color: $grey-darker;
          }
        }
      }
    }

    &.is-opened {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }

    @include responsive(t) {
      right: -62px;
    }
  }
}
