input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

@function makelongshadow($color, $size) {
  $val: 5px 0 0 $size $color;

  @for $i from 6 through $slider-width-number {
    $val: #{$val}, #{$i}px 0 0 $size #{$color};
  }

  @return $val;
}


@mixin customRange($slider-height, $background-slider, $background-filled-slider, $thumb-width, $thumb-height, $thumb-radius, $thumb-background, $thumb-border, $shadow-size, $fit-thumb-in-slider) {
  appearance: none;
  background: none;
  overflow: hidden;
  width: $slider-width;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    background: $background-filled-slider;
    border-radius: $slider-height;
    height: $slider-height;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    background: $thumb-background;
    box-shadow: makelongshadow($background-slider, $shadow-size), 0 2px 4px 0 $shadow;
    border: $thumb-border;
    border-radius: $thumb-radius;
    cursor: grab;
    height: $thumb-height;
    margin-top: $fit-thumb-in-slider;
    width: $thumb-width;

    &:focus {
      cursor: grabbing;
    }
  }

  &::-moz-range-track {
    border-radius: $slider-height;
    height: $slider-height;
    width: $slider-width;
  }

  &::-moz-range-thumb {
    background: $thumb-background;
    border: $thumb-border;
    border-radius: $thumb-radius;
    cursor: grab;
    height: $thumb-height;
    position: relative;
    width: $thumb-width;
  }

  &::-moz-range-progress {
    background: $background-filled-slider;
    border: 0;
    border-radius: $slider-height;
    height: $slider-height;
    margin-top: 0;
  }

  &::-ms-track {
    background: transparent;
    border: 0;
    border-radius: $slider-height;
    color: transparent;
    height: $slider-height;
    margin-top: 10px;
    width: $slider-width;
  }

  &::-ms-thumb {
    background: $thumb-background;
    border: $thumb-border;
    border-radius: $thumb-radius;
    cursor: grab;
    height: $thumb-height;
    width: $thumb-width;
  }

  &::-ms-fill-lower {
    border-radius: 0;
  }

  &::-ms-fill-upper {
    background: $background-slider;
    border-radius: 0;
  }

  &::-ms-tooltip {
    display: none;
  }

  &:focus {
    &::-ms-thumb {
      cursor: grabbing;
    }

    &::-moz-range-thumb {
      cursor: grabbing;
    }

    &::-ms-thumb {
      cursor: grabbing;
    }
  }
}
