body {
  background: $grey-lighter;
}

.main-container {
  margin-top: 80px;
  padding-top: 40px;

  .container {
    overflow: hidden;
    max-width: $size-container-mobile;
    width: 100%;

    @include responsive(t) {
      overflow: inherit;
      max-width: $size-container-desktop;
    }
  }

  @include responsive(t) {
    margin-top: 98px;
  }
}

