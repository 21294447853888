.form-validation {
  @include clearfix();

  .checkbox {
    @include font-family($main-font-bold);
    float: left;
    margin-left: 28px;

    &-text {
      display: block;
      max-width: 220px;
    }
  }

  &-action {
    background: $white;
    border-top: 1px solid $grey-light;
    bottom: 0;
    left: 0;
    padding: 24px 16px;
    position: fixed;
    width: 100%;

    .btn {
      width: 100%;
    }

    @include responsive(t-xs) {
      background: inherit;
      border: 0;
      bottom: inherit;
      float: right;
      left: inherit;padding: 0;
      position: relative;
    }
  }
}
