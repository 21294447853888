.scheduler {
  position: relative;

  &-grid {
    padding: 40px 0 0 20px;
    width: 100%;

    @include responsive(s-xl) {
      padding: 55px 0 0 20px;
    }
  }

  &-background {
    &-item {
      margin-bottom: 20px;
      position: relative;

      &-title {
        @include font-size(10);
        color: $grey;
        left: -22px;
        letter-spacing: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &-line {
        background: $grey-light;
        height: 2px;
        width: 100%;
      }
    }
  }

  &-planning {
    height: 100%;
    position: absolute;
    right: 6px;
    top: 0;
    width: calc(100% - 32px);

    @include responsive(s-xl) {
      width: calc(100% - 20px);
    }
  }

  &-column {
    $scopeColumn: &;
    float: left;
    height: 100%;
    position: relative;
    width: calc(100% / 7);

    &-header {
      &-item {
        display: block;

        &-circle {
          @include circle(24px);
          background: $primary-color;
          margin: 0 auto;
          position: relative;

          @include responsive(s-xl) {
            @include circle(48px);
          }
        }

        &-text {
          @include absolute-centered();
          @include font-family($main-font-heavy);
          @include font-size(13);
          color: $white;
          letter-spacing: 0;
          line-height: 2.4rem;
          text-transform: uppercase;

          @include responsive(s-xl) {
            @include font-size(24);
          }
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    &-body {
      background: $grey-light;
      cursor: pointer;
      height: calc(100% - 40px);
      left: 50%;
      position: absolute;
      top: 40px;
      transform: translateX(-50%);
      width: calc(100% - 2px);

      @include responsive(s-xl) {
        height: calc(100% - 55px);
        top: 55px;
        width: 80%;
      }
    }

    &-backdrop {
      cursor: pointer;
      height: 100%;
      width: 100%;
      z-index: -1;
      position: absolute;
      top: 0;
    }

    &.empty {
      #{$scopeColumn} {
        &-header {
          &-item {
            &-circle {
              background: $grey-light;
            }

            &-text {
              color: $grey;
            }
          }
        }
      }
    }
  }

  &-time-slot {
    @include font-family($main-font-heavy);
    @include font-size(12);
    border-radius: 4px;
    color: $white;
    height: 9px;
    letter-spacing: 0;
    margin-top: 2px;
    padding: 4px;
    position: relative;

    &-filled {
      background: $primary-color;

      &.expensive {
        background: $third-color;
      }
    }

    &-filling {
      background: $white;
      border: 2px solid $primary-color;
      box-shadow: 0 2px 4px 0 $shadow;
    }
  }
}
