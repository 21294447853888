.header-profile {
  display: inline-block;
  margin: 2px 0;

  &-img,
  &-info {
    display: inline-block;
    vertical-align: middle;
  }

  &-img {
    @include circle(44px);
    overflow: hidden;
    position: relative;
    margin-right: 16px;

    img {
      @include absolute-centered();
      width: 160%;
    }

    @include responsive(t) {
      @include circle(60px);
    }
  }

  &-info {
    &-title {
      @include font-family($main-font-black);
      @include font-size(14);
      color: $grey-darker;
      letter-spacing: 0;
      line-height: 2.4rem;

      @include responsive(t) {
        @include font-size(24);
      }
    }

    &-status {
      @include font-family($main-font-heavy);
      @include font-size(12);
      color: $primary-color;
      letter-spacing: 0;
      line-height: 1.4rem;
      display: block;

      @include responsive(t) {
        line-height: 2.4rem;
      }
    }
  }

  @include responsive(t) {
    margin: -6px 0 -6px 84px;
  }
}
