.not-found {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &-number {
    position: absolute;
    z-index: -1;
    top: 20px;
    font-size: 404px;
    transform: scale(1, 0.9);
    font-weight: bold;
    color: $primary-color;
  }

  &-text {
    text-align: center;
    padding: 20px;
    font-size: 14px;
  }

  .btn-primary {
    width: 75%;
  }

  &-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .illustration-city {
      height: 340px;
      width: 390px;
    }

  }
}
