.form-area {
  &-input {
    @include font-size(12);
    @include placeholder($grey);
    background: $grey-lighter;
    border: 1px solid $grey;
    border-radius: 2px;
    color: $grey;
    letter-spacing: 0;
    line-height: 1.8rem;
    margin-bottom: 8px;
    max-height: 92px;
    padding: 10px;
    resize: none;
    width: 100%;

    @include responsive(t-xs) {
      padding: 14px 16px;
    }

    &:focus {
      outline: 0;
    }
  }
}
