.actions {
  position: relative;

  &-label {
    @include font-family($main-font-heavy);
    @include font-size(12);
    color: $grey-darker;
    letter-spacing: 0;
    line-height: 1.8rem;
    margin-bottom: 12px;

    @include responsive(s-xl) {
      margin-bottom: 16px;
      max-width: calc(100% - 150px);
    }
  }

  .text-legend {
    @include responsive(s-xl) {
      max-width: calc(100% - 150px);
    }
  }

  .btn {
    float: right;

    @include responsive(s-xl) {
      float: inherit;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-block {
    @include clearfix();
    background: $white;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 $shadow;
    margin-bottom: 20px;
    padding: 18px 24px;
    position: relative;

    &-content {
      margin-bottom: 16px;
      width: 100%;

      @include responsive(s-xl) {
        position: absolute;
        left: 24px;
        margin-bottom: 0;
        max-width: 48%;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &-title {
      @include font-family($main-font-heavy);
      @include font-size(16);
      color: $primary-color;
      letter-spacing: 0;
      line-height: 1.8rem;
      margin-bottom: 4px;
    }

    &-text {
      @include font-size(12);
      color: $grey-darker;
      letter-spacing: 0;
      line-height: 1.6rem;
    }

    .btn {
      float: right;
      max-width: 160px;
    }
  }
}

.section-actions {
  &-title {
    @include font-family($main-font-heavy);
    @include font-size(16);
    color: $primary-color;
    letter-spacing: 0;
    line-height: 2.4rem;
    margin-bottom: 16px;
  }
}
