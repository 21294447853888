.parking {
  padding-bottom: 98px;
  padding-top: 0;

  &-height,
  &-url {
    margin-top: 14px;

    .icon-height,
    .form-input {
      vertical-align: middle;
    }

    .icon-height {
      margin-right: -4px;
    }
  }

  &-height {
    .form-input {
      display: inline-block;
      max-width: calc(100% - 48px);
    }
  }

  .header {
    &-left {
      .link-text {
        display: none;

        @include responsive(t) {
          display: inline-block;
        }
      }
    }

    &-right {
      display: none;

      @include responsive(t) {
        display: block;
      }
    }
  }

  @include responsive(t) {
    padding-top: 20px;
  }
}
