.validation {
  padding-bottom: 280px;

  .illustration-city {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(47%, -32%);

    @include responsive(t-xs) {
      display: block;
    }

    @include responsive(t) {
      transform: translate(33%, -32%);
      display: block;
    }
  }

  .section-actions-title {
    @include responsive(t-xs) {
      margin-top: 24px;
    }
  }
}
