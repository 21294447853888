.matriculation {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $shadow;
  color: $grey-darker;
  display: flex;
  flex-wrap: wrap;
  letter-spacing: 0;
  margin-bottom: 10px;
  padding:  16px 46px 16px 24px;
  position: relative;

  @include responsive(t-xs) {
    display: flex;
    flex-wrap: wrap;
    padding:  20px 84px 20px 24px;
  }

  &-name,
  &-power {
    flex: 0 1 100%;
    margin-bottom: 10px;

    &-title {
      @include font-family($main-font-regular);
      @include font-size(12);
      color: $grey-dark;
      line-height: 1.8rem;
    }

    &-content {
      @include font-family($main-font-bold);
      @include font-size(18);
      color: $grey-darker;
      line-height: 2.4rem;
    }

    @include responsive(t-xs) {
      flex: 0 1 calc(50% - 12px);
      margin-bottom: 16px;
    }
  }


  &-date {
    flex: 0 1 calc(50% - 5px);

    &-title {
      @include font-family($main-font-regular);
      @include font-size(10);
      color: $grey-dark;
      line-height: 1.2rem;
      margin-bottom: 4px;

      @include responsive(t-xs) {
        @include font-size(12);
        line-height: 1.8rem;
        margin-bottom: 0;
      }
    }

    &-content {
      @include font-family($main-font-bold);
      @include font-size(12);
      color: $grey-darker;
      line-height: 1.2rem;

      @include responsive(t-xs) {
        @include font-size(18);
        line-height: 2.4rem;
      }
    }

    &:first-child {
      margin-right: 10px;

      @include responsive(t-xs) {
        margin-right: 0;
      }
    }

    @include responsive(t-xs) {
      flex: 0 1 calc(50% - 12px);
    }
  }

  .icon-options {
    position: absolute;
    right: 16px;
    top: 24px;

    @include responsive(t-xs) {
      right: 24px;
    }
  }
}
