.info {
  $self: &;
  background: $white;
  bottom: 24px;
  box-shadow: 0 2px 4px 0 $shadow;
  display: none;
  left: 50%;
  opacity: 0;
  padding: 24px;
  position: absolute;
  transition: display 0s linear .4s, opacity .4s ease-in-out 0s;
  transform: translateX(-50%);
  width: 284px;

  &::before {
    border-style: solid;
    border-width: 4px;
    border-color: $white transparent  transparent transparent ;
    bottom: -8px;
    content: '';
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }

  &-wrapper {
    position: relative;
    z-index: 1;

    .icon-info {
      display: flex;
    }

    &:hover {
      #{$self} {
        display: flex;
        opacity: 1;
        transition: display 0s linear 0s, opacity .4s ease-in-out 0s;
      }
    }
  }

  &-content {
    @include font-size(12);
    color: $grey;
    line-height: 1.6rem;

    span {
      @include font-family($main-font-bold);
    }

    a {
      color: $primary-color;
      text-decoration: underline;

      &:hover {
        color: $primary-color;
      }
    }
  }
}
