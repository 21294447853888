.switch {
  @include clearfix();
  background-color: $grey-light;
  border-radius: 43px;
  display: block;
  height: 34px;
  padding: 1px;
  position: relative;
  width: 223px;

  &-text {
    @include font-family($main-font-heavy);
    @include font-size(12);
    color: $primary-color;
    cursor: pointer;
    float: left;
    font-weight: inherit;
    letter-spacing: .5px;
    line-height: 2.2rem;
    margin: 0;
    padding: 5px 28px;
    position: relative;
    text-transform: uppercase;
    z-index: map-get($zindex, lvl0-2);
  }

  &-input {
    display: none;
  }

  &-moving-bg {
    background: $white;
    border-radius: 34px;
    display: block;
    height: calc(100% - 2px);
    left: 1px;
    position: absolute;
    top: 1px;
    transition: all .25s cubic-bezier(.52, .07, 0, .89);
    width: 135px;
    z-index: map_get($zindex, lvl0-1);
  }
}
