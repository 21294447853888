.customer {
  $customer: &;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $shadow;
  margin-bottom: 10px;
  position: relative;

  &-content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding:  14px 74px 18px 24px;
  }

  &-name,
  &-list,
  &-period {
    flex: 0 1 100%;
    margin: 0 16px 0 0;

    &-title {
      @include font-size(10);
      color: $grey;
      display: block;
      line-height: 1.2rem;
      margin-bottom: 4px;
    }

    &-content {
      @include font-family($main-font-bold);
      @include font-size(14);
      @include hide-scrollbar();
      align-items: center;
      display: block;
      line-height: 1.2rem;
      padding-bottom: 2px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @include responsive(t-xs) {
      flex: 0 1 65%;
      margin-bottom: 0;
      max-width: 65%;
    }
  }

  &-list {
    display: none;
    margin: 0;
  }

  .btn {
    margin-top: 20px;

    @include responsive(t-xs) {
      margin-left: auto;
      margin-top: 0;
    }
  }

  .icon-user {
    @include img-size(16px, 16px);
    display: none;
    margin-left: 4px;
  }

  .icon-options {
    position: absolute;
    right: 28px;
    top: 24px;

    @include responsive(s-xl) {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-edit {
    justify-content: flex-end;
    padding:  14px 24px 18px 14px;

    .form-input {
      @include responsive(t-xs) {
        margin-bottom: 0;
        width: 43%;
      }
    }

    .btn {
      margin-top: 0;
    }

    &-form {
      padding-bottom: 4px;
      width: 100%;

      @include responsive(t-xs) {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      .form-input {
        @include responsive(t-xs) {
          margin-bottom: 16px;
          width: 40%;
        }
      }

      .calendar-trigger-wrapper {
        .form-input {
          @include responsive(t-xs) {
            width: 100%;
          }
        }

        @include responsive(t-xs) {
          width: 55%;
        }
      }

      .calendar-popper-footer {
        .btn {
          @include responsive(t-xs) {
            margin: 0;
          }
        }
      }
    }

    @include responsive(t-xs) {
      justify-content: space-between;
    }
  }

  &.approved {
    .icon-user {
      @include img-size(16px, 16px);
      display: inline-flex;
      margin-left: 4px;
      vertical-align: sub;
    }

    .btn {
      display: none;
    }
  }

  &.listing {
    #{$customer} {
      &-name,
      &-list,
      &-period {
        @include responsive(t-xs) {
          flex: 0 1 33%;
          max-width: 33%;
        }

        @include responsive(t) {
          flex: 0 1 33%;
          max-width: 33%;
        }
      }
    }
  }

  &.searching {
    #{$customer} {
      &-name,
      &-list,
      &-period {
        @include responsive(t-xs) {
          flex: 0 1 30%;
          max-width: 30%;
        }

        @include responsive(t) {
          flex: 0 1 35%;
          max-width: 35%;
        }
      }

      &-period {
        display: none;
      }

      &-list {
        display: none;

        @include responsive(t-xs) {
          display: block;
        }
      }
    }
  }
}
