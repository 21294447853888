.partner {
  @include clearfix();
  background: $white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $shadow;
  margin-bottom: 10px;
  padding: 24px;
  position: relative;

  &-title {
    @include font-family($main-font-heavy);
    @include font-size(14);
    color: $grey-darker;
    float: left;
    letter-spacing: 0;
    line-height: 1.4rem;
    margin-bottom: 4px;
    width: 100%;

    @include responsive(s-xl) {
      margin-bottom: 0;
      width: inherit;
    }
  }

  &-content {
    @include font-size(14);
    float: left;
    letter-spacing: 0;
    line-height: 1.2rem;

    @include responsive(s-xl) {
      @include absolute-centered();
      float: inherit;
    }
  }

  &-action {
    @include font-family($main-font-bold);
    @include font-size(14);
    color: $primary-color;
    float: right;
    letter-spacing: 0;
    line-height: 1.2rem;

    &:hover {
      color: $primary-color;
    }
  }
}
