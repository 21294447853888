.modal-validation {
  &-body {
    .actions-label {
      max-width: inherit;
    }

    .text-legend {
      margin-bottom: 24px;
    }
  }

  @include responsive(s-xl) {
    transform: translate(-50%, -50%);
    top: 50%;
  }
}
