.alerts {
  @include clearfix();
  background: $primary-color;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $shadow;
  margin-bottom: 8px;
  padding: 8px 24px 8px 64px;
  position: relative;

  &-wrapper {
    margin: 0 auto 12px;
    max-width: $size-container-mobile;

    &.alerts-off-canvas {
      left: 50%;
      margin: 0;
      position: fixed;
      top: 0;
      transform: translate(-50%, -150%);
      transition: transform .4s ease-in-out;
      width: 100%;
      z-index: map_get($zindex, lvl2);

      .alerts {
        width: 100%;
      }
    }

    @include responsive(t-xs) {
      max-width: $size-container-desktop;
    }
  }

  .icon-warning-white {
    left: 18px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &-content {
    position: relative;
  }

  &-title {
    @include font-family($main-font-heavy);
    @include font-size(14);
    color: $white;
    letter-spacing: 0;
    line-height: 1.8rem;
    margin-bottom: 2px;
  }

  &-text {
    @include font-family($main-font-medium);
    @include font-size(12);
    color: $white;
    letter-spacing: 0;

    @include responsive(t-xs) {
      max-width: calc(100% - 200px);
    }

    &-link {
      @include font-family($main-font-black);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .btn {
    display: none;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);

    @include responsive(t-xs) {
      display: block;
    }
  }

  &-warning {
    background: $secondary-color;

    .icon-warning-white {
      @include svg-bg('ic_alert_orange');
    }
  }

  &-success {
    background: $positive;

    .icon-warning-white {
      @include svg-bg('ic_alert_check_green');
    }
  }
}

.alerts-on {
  .alerts-off-canvas {
    transform: translate(-50%, 108px);
  }
}
