.search-banner {
  border-bottom: 0 solid $grey-light;
  margin-bottom: 24px;
  padding: 16px 0;
  transition: all .25s cubic-bezier(.85, .01, .58, 1);
  z-index: map_get($zindex, lvl1);

  .title-main,
  .form-input {
    margin-bottom: 0;
  }

  .title-main {
    color: $secondary-color;
    line-height: 4.8rem;
  }
}

.scrolling {
  .search-banner-scroll {
    background: $white;
    border-bottom: 1px solid $grey-light;
    left: 0;
    position: fixed;
    top: 96px;
    width: 100%;
  }
}
