.radio-button {
  @include font-size(12);
  color: $grey-darker;
  cursor: pointer;
  display: block;
  font-weight: inherit;
  letter-spacing: 0;
  line-height: 1.6rem;
  margin-bottom: 16px;
  padding-left: 48px;
  position: relative;

  input {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;

    &:checked {
      ~ .checkmark {
        background: $primary-color;
        border: 1px solid $white;

        .icon-check {
          display: inline-block;
        }
      }
    }
  }

  .checkmark {
    background: $white;
    border: 1px solid $grey-darker;
    border-radius: 4px;
    height: 24px;
    left: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all .25s ease;
    width: 24px;

    .icon-check {
      display: none;
      position: absolute;
      left: -1px;
      top: -1px;
    }
  }
}