@mixin clearfix {
  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }
}

@mixin absolute-centered {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

@mixin hori-centered {
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

@mixin circle($radius: 32px) {
  border-radius: $radius;
  height: $radius;
  line-height: $radius;
  padding: 0;
  width: $radius;
}

@mixin box-shadow($top: 0, $left: 0, $blur: 0, $size: 0, $color: '', $inset: false) {
  @if $top == 0 and $left == 0 and $blur == 0 or $top == 'none' {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  } @else if $inset {
    -webkit-box-shadow: inset $top $left $blur $size $color;
    -moz-box-shadow: inset $top $left $blur $size $color;
    box-shadow: inset $top $left $blur $size $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $size $color;
    -moz-box-shadow: $top $left $blur $size $color;
    box-shadow: $top $left $blur $size $color;
  }
}

@mixin placeholder($color) {
  &::placeholder {
    color: $color;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $color;
  }

  &::-ms-input-placeholder {
    color: $color;
  }
}

@mixin linear-gradient($top, $bottom) {
  background: $top; /* Old browsers */
  background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
  background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

@mixin btn-color($color1, $color2, $sameHover: false, $invert: false) {
  @if ($invert) {
    background: $color1;
    border-color: $color1;
    color: $color1;

    &::before {
      background: $color2;
    }

    &:hover,
    &:active,
    &:focus,
    &:focus:active {
      background: $color1;
      border-color: $color1;
    }

    &:focus {
      color: $color1;
    }

    &:hover {
      color: $color2;
    }
  } @else {
    background: $color1;
    border-color: $color1;
    color: $color2;
    transition: 0s background-color .4s cubic-bezier(.85, .01, .58, 1), color .4s;

    &::before {
      background: $color1;
    }

    &:hover,
    &:active,
    &:focus,
    &:focus:active {
      background: transparent;
      border-color: $color1;
      transition: 0s background-color 0s cubic-bezier(.85, .01, .58, 1);
    }

    &:hover {
      @if ($sameHover) {
        color: $color2;
      } @else {
        color: $color1;
      }
    }
  }
}

@mixin responsive($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
                    type-of($raw-query) == 'string',
                    unquote($raw-query),
                    inspect($raw-query)
    );

    @media all and (min-width: #{$query}) {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map. Take a look in _variables.scss';
  }
}

@mixin rangeInput() {
  &::-ms-thumb,
  &::-moz-range-thumb,
  &::-webkit-slider-thumb {
    @content;
  }
}

@mixin rangeTrack() {
  &::-ms-track,
  &::-moz-range-track,
  &::-webkit-slider-runnable-track {
    @content;
  }
}

@mixin hide-scrollbar() {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    background: transparent;
    display: none;
    height: 0 !important;
    width: 0 !important;
  }
}
