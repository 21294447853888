$breakpoints: (
        s-xxs: 320px,
        s-xs: 375px,
        s: 480px,
        s-xl: 576px,
        t-xs: 768px,
        t: 992px,
        t-xl: 1024px,
        d: 1200px,
);

$zindex: (
        lvl-1: -1,
        lvl0: 0,
        lvl0-1: 1,
        lvl0-2: 2,
        lvl1: 10,
        lvl2: 25,
        lvl3: 50,
        lvl4: 100,
        lvl5: 110,
);

$white: #fff;
$grey-lighter: #f8f8f8;
$grey-light: #f1f1f1;
$grey-medium: #d8d8d8;
$grey: #afafaf;
$grey-dark: #969696;
$grey-darker: #4d5859;
$black: #000;

$shadow: rgba(0, 0, 0, .12);

$primary-color: #00b5e2;
$secondary-color: #ff8500;
$third-color: #006983;

$primary-transparent-color: rgba(0, 181, 226, .33);

$error: #ff6f6f;

$negative: #dc3545;
$positive: #78be20;

$green: #529400;

$stats-color: (
  stats-color-1: $grey-light,
  stats-color-2: $primary-color,
  stats-color-3: $secondary-color,
);

$cubic-bezier: cubic-bezier(.34, .23, .4, .99);

$main-font-regular:           'SF-Pro-Display-Regular';
$main-font-regular-path:      '/fonts/SF-Pro-Display-Regular';
$lemonway-font-regular-path:      '/fonts/Bookandplug_SF-Pro-Display-Regular';
$main-font-medium:           'SF-Pro-Display-Medium';
$main-font-medium-path:      '/fonts/SF-Pro-Display-Medium';
$lemonway-font-medium-path:      '/fonts/Bookandplug_SF-Pro-Display-Medium';
$main-font-bold:              'SF-Pro-Display-Bold';
$main-font-bold-path:         '/fonts/SF-Pro-Display-Bold';
$lemonway-font-bold-path:         '/fonts/Bookandplug_SF-Pro-Display-Bold';
$main-font-black:             'SF-Pro-Display-Black';
$main-font-black-path:        '/fonts/SF-Pro-Display-Black';
$lemonway-font-black-path:        '/fonts/Bookandplug_SF-Pro-Display-Black';
$main-font-heavy:             'SF-Pro-Display-Heavy';
$main-font-heavy-path:        '/fonts/SF-Pro-Display-Heavy';
$lemonway-font-heavy-path:        '/fonts/Bookandplug_SF-Pro-Display-Heavy';

$doc-general-img-path:    '/img/';

$grid-gutter-width: 20px;

$size-container-mobile: 992px;
$size-container-desktop: 960px;

$slider-width-number: 1000;
$slider-width: #{$slider-width-number}px;
