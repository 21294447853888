body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
input[type=number] {
    -moz-appearance: textfield;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.inputWrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    /*Using a background color, but you can use a background image to represent a button*/
    background-color: #DDF;
}
.fileInput {
    cursor: pointer;
    height: 100%;
    width: 100%;
    position:absolute;
    top: 0;
    right: 0;
    z-index: 99;
    /*This makes the button huge. If you want a bigger button, increase the font size*/
    font-size:50px;
    /*Opacity settings for all browsers*/
    opacity: 0;
    -moz-opacity: 0;
    filter:progid:DXImageTransform.Microsoft.Alpha(opacity=0)
}
div.show-image {
    float:left;
    margin:0;
}
div.show-image:hover img{
    opacity:0.5;
}
div.show-image:hover a {
    display: block;
}
div.show-image a {
    position:absolute;
    display:none;
}



.question {
    color: #4d5859;
    display: block;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    margin-bottom: 10px;
    letter-spacing: 0;
    padding: 14px 0;
    position: relative;
}
.question-title {
    font-family: "SF-UI-Display-Bold";
    font-size: 18px;
    font-size: 1.8rem;
    cursor: pointer;
    line-height: 1.4rem;
    padding: 0 14px;
}
.question-content {
    padding: 14px 14px 0;
}
.question-content-title {
    font-family: "SF-UI-Display-Bold";
    font-size: 16px;
    font-size: 1.6rem;
    color: #00b5e2;
}
.question-content-text {
    margin-bottom: 16px;
}
