.modal-hours,
.modal-cross-hours,
.modal-scheduler,
.modal-full-hours,
.modal-validation {
  background: $white;
  bottom: 0;
  display: none;
  max-height: 100vh;
  left: 0;
  overflow-y: auto;
  padding: 80px 24px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;

  &-title {
    @include font-family($main-font-heavy);
    @include font-size(12);
    color: $primary-color;
    letter-spacing: 1px;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  .btn-close {
    position: absolute;
    right: 24px;
    top: 24px;

    @include responsive(s-xl) {
      right: 8px;
      top: 8px;
    }
  }

  &-actions {
    background: $white;
    border-top: 1px solid $grey-light;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    left: 0;
    padding: 16px 24px;
    position: fixed;
    width: 100%;

    .btn {
      width: calc(50% - 8px);

      @include responsive(t-xs) {
        width: 140px;
      }
    }

    @include responsive(s-xl) {
      border: 0;
      bottom: inherit;
      padding: 0;
      left: inherit;
      position: relative;
    }
  }

  .btn-primary {
    margin-left: 12px;
  }

  @include responsive(s-xl) {
    border-radius: 4px;
    bottom: inherit;
    box-shadow: 0 2px 4px 0 $shadow;
    height: auto;
    left: 50%;
    max-height: inherit;
    padding: 16px 24px;
    position: absolute;
    transform: translateX(-50%);
    top: inherit;
    width: 360px;
  }
}

.modal-layer-screen {
  bottom: 0;
  display: none;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

