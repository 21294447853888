.section {
  &-home {
    padding: 32px 0;

    @include responsive(t-xs) {
      padding: 64px 0;
    }

    @include responsive(t) {
      padding: 96px 0;
    }
  }

  &-intro {
    background: $primary-color;
    padding: 20px 0 32px;
    position: relative;

    .container {
      position: relative;
    }

    .illustration-city {
      display: none;
      left: -50%;
      position: absolute;
      top: 0;

      @include responsive(t) {
        display: block;
      }
    }

    .login-block {
      display: none;
      position: absolute;
      right: 24px;
      text-align: center;
      top: 24px;

      .text-info {
        color: $white;
        display: block;
        margin-bottom: 4px;
      }

      @include responsive(d) {
        display: block;
      }
    }

    .form-block {
      margin-bottom: 0;

      .select {
        margin-bottom: 16px;

        @include responsive(t) {
          margin-bottom: 0;
        }
      }

      .text-info {
        @include font-family($main-font-regular);
        @include font-size(16);
        color: $grey-darker;
        display: block;
        margin-bottom: 16px;
        letter-spacing: 0;
        text-align: center;
        text-transform: none;

        .text-highlight {
          @include font-family($main-font-black);
          @include font-size(32);
          color: $primary-color;
          margin-right: 10px;
          vertical-align: middle;

          @include responsive(t) {
            display: block;
            margin-bottom: -6px;
            margin-right: 0;
          }
        }

        @include responsive(t) {
          margin-bottom: 12px;
        }
      }

      .btn-block {
        display: inline-block;
        margin: 0 10px 0 0;
        max-width: calc((100% / 2) - 7px);
        vertical-align: middle;

        &:last-of-type {
          margin-right: 0;
        }

        @include responsive(t) {
          max-width: 100%;
        }
      }
    }

    @include responsive(t) {
      padding: 64px 0 128px;
    }
  }

  &-explain {
    background: $white;
    padding-left: 14px;
    padding-right: 14px;

    @include responsive(t-xs) {
      padding-left: 0;
      padding-right: 0;
    }

    @include responsive(t) {
      padding-top: 128px;
    }
  }

  &-steps {
    padding-left: 14px;
    padding-right: 14px;

    @include responsive(t-xs) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-testimonial {
    background: $white;
    padding-left: 14px;
    padding-right: 14px;

    @include responsive(t-xs) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-payment {
    padding-bottom: 0;
    padding-left: 14px;
    padding-right: 14px;

    @include responsive(t-xs) {
      padding-bottom: 96px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-world {
    background: inherit;
    padding: 0;
  }

  &-promo {
    padding: 24px 10px;

    @include responsive(t) {
      padding: 48px;
    }
  }

  &-subtitle {
    @include font-family($main-font-black);
    @include font-size(12);
    color: $grey-darker;
    letter-spacing: .43px;
    margin-bottom: 8px;
    text-align: center;
    text-transform: uppercase;

    @include responsive(t-xs) {
      @include font-size(14);
      letter-spacing: .5px;
      margin-bottom: 10px;
    }
  }

  &-title {
    @include font-family($main-font-black);
    @include font-size(24);
    color: $primary-color;
    line-height: 2.8rem;
    margin-bottom: 32px;
    text-align: center;

    @include responsive(t-xs) {
      @include font-size(48);
      line-height: 5.6rem;
      margin-bottom: 64px;
    }
  }
}
