.promo {
  position: relative;

  &-content {
    padding: 0 32px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  &-title {
    @include font-family($main-font-black);
    @include font-size(32);
    color: $white;
    letter-spacing: 0;
    line-height: 3.6rem;
    margin-bottom: 24px;

    @include responsive(t-xs) {
      @include font-size(48);
      margin-bottom: 32px;
    }
  }

  .full-img-wrapper {
    height: 208px;

    @include responsive(t-xs) {
      height: 350px;
    }
  }
}
