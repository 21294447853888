.header-user {
  @include clearfix();
  padding: 9px 24px 8px 0;
  text-align: right;

  &-name {
    @include font-family($main-font-heavy);
    @include font-size(12);
    color: $primary-color;
    display: block;
    letter-spacing: .43px;
    text-transform: uppercase;

    &:hover {
      color: $primary-color;
    }
  }

  &-connexion {
    @include font-size(10);
    color: $grey;
    display: block;
    letter-spacing: 0;

    &:hover {
      color: $grey;
    }
  }

  &-info,
  .icon-user {
    float: right;
  }

  .icon-user {
    margin-left: 16px;
    margin-top: -8px;
  }
}

.account,
.dashboard,
.creation,
.validation {
  .header {
    &-user {
      display: none;

      @include responsive(t) {
        display: block;
      }
    }
  }
}
