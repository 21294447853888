.users-list-item {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $shadow;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;

  &-content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding:  14px 74px 18px 24px;
  }

  &-title {
    @include font-size(10);
    color: $grey;
    display: block;
    line-height: 1.2rem;
    margin-bottom: 4px;
  }

  //edit overflow//
  &-value {
    @include font-family($main-font-bold);
    @include font-size(14);
    align-items: center;
    display: block;
    line-height: 1.2rem;
    padding-bottom: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-name,
  &-number {
    flex: 0 1 100%;
    margin: 0 16px 0 0;

    @include responsive(t-xs) {
      flex: 0 1 35%;
      margin-bottom: 0;
      max-width: 35%;
    }
  }

  &-number {
    display: none;
    margin: 0;

    @include responsive(t-xs) {
      display: block;
    }
  }

  &-edit {
    justify-content: flex-end;
    padding:  14px 24px 18px 14px;

    .form-input {
      @include responsive(t-xs) {
        margin-bottom: 0;
        width: 43%;
      }
    }

    .btn {
      margin-top: 0;
    }

    @include responsive(t-xs) {
      justify-content: space-between;
    }
  }

  .icon-chevron {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
  }
}
