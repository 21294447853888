.select {
  $scopeSelect: &;
  cursor: pointer;
  position: relative;
  width: 100%;

  &-input {
    padding: 16px 10px 8px 0;
    width: 100%;

    &::before {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &::before {
      border-bottom: 2px solid $grey-light;
    }
  }

  &-label {
    @include font-family($main-font-regular);
    @include font-size(14);
    align-items: center;
    color: $grey-dark;
    display: flex;
    letter-spacing: 0;
    line-height: 2rem;
    left: 0;
    margin: 0;
    position: absolute;
    transition: transform .4s cubic-bezier(.12, .01, 0, .99);
    transform-origin: left;
    top: 14px;
    width: 100%;
  }

  &-value {
    @include font-family($main-font-bold);
    @include font-size(18);
    color: $grey-darker;
    letter-spacing: 0;
    line-height: 2.4rem;
    opacity: 0;
    transition: opacity .4s cubic-bezier(.12, .01, 0, .99) .25s;
  }

  .icon-arrow-select {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform .25s ease;
  }

  &-box {
    background: $white;
    max-height: 0;
    overflow-y: auto;
    position: absolute;
    top: 48px;
    transition: max-height .4s cubic-bezier(.12, .01, 0, .99);
    width: 100%;
    z-index: map_get($zindex, lvl0-1);

    &-list {
      &-item {
        @include font-family($main-font-bold);
        @include font-size(18);
        color: $grey-dark;
        padding: 14px 10px;

        &:hover {
          background: $grey-light;
        }
      }
    }
  }

  &.is-opened {
    .icon-arrow-select {
      transform: translateY(-50%) rotate(180deg);
    }


    #{$scopeSelect} {
      &-box {
        max-height: 200px;
      }
    }
  }

  &.is-filled {
    #{$scopeSelect} {
      &-input {
        &:after {
          transform: translate(0, 0);
        }
      }

      &-label {
        transform: translate(0, -16px) scale(.8);
      }

      &-value {
        opacity: 1;
      }
    }
  }

  &.state-1 {
    #{$scopeSelect} {
      &-input {
        &:after {
          border-bottom: 2px solid $secondary-color;
        }
      }
    }
  }

  &.state-2 {
    #{$scopeSelect} {
      &-input {
        &:after {
          border-bottom: 2px solid $grey;
        }
      }
    }
  }

  &.state-3 {
    #{$scopeSelect} {
      &-input {
        &:after {
          border-bottom: 2px solid $positive;
        }
      }
    }
  }

  &.state-4 {
    #{$scopeSelect} {
      &-input {
        &:after {
          border-bottom: 2px solid $negative;
        }
      }
    }
  }
}
