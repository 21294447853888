.search {
  &-back {
    @include font-family($main-font-heavy);
    @include font-size(12);
    align-items: center;
    color: $primary-color;
    display: flex;
    letter-spacing: .86px;
    line-height: 1.6rem;
    margin-bottom: 24px;
    margin-top: 8px;
    text-transform: uppercase;

    .icon-arrow-left {
      @include svg-bg('arrow_fat_back');
      @include img-size(20px, 20px);
      display: inline-block;
      margin-right: 14px;
    }

    &:hover {
      color: $primary-color;
    }
  }

  &-info {
    align-items: baseline;
    display: flex;
    margin: -6px 0 20px;
    padding-left: 10px;

    &-number {
      @include font-family($main-font-black);
      @include font-size(12);
      color: $grey-darker;
      display: inline-flex;
      line-height: 2.4rem;
      margin-right: 4px;
    }

    &-label {
      @include font-size(14);
      color: $grey-darker;
      display: inline-flex;
      line-height: 2.1rem;
    }
  }

  &-title {
    @include font-family($main-font-black);
    @include font-size(16);
    color: $grey-darker;
    line-height: 2.4rem;
    margin: 14px 0 12px;
    text-align: center;

    @include responsive(s-xl) {
      margin: 14px auto 12px;
      max-width: 65%;
    }
  }

  &-text {
    @include font-size(16);
    color: $grey-darker;
    line-height: 2.4rem;
    text-align: center;

    @include responsive(s-xl) {
      margin: 0 auto;
      max-width: 65%;
    }
  }
}
