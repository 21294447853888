.modal-cross-hours {
  &-title {
    color: $secondary-color;
  }

  &-img {
    padding: 16px 0 32px;

    img {
      width: 100%;
    }
  }

  &-actions {
    .btn {
      @include responsive(t-xs) {
        margin-bottom: 8px;
        width: 154px;
      }
    }

    @include responsive(t-xs) {
      justify-content: center;
    }
  }

  @include responsive(s-xl) {
    transform: translate(-50%, -50%);
    top: 50%;
    width: 434px;
  }
}
