.subscription {
  padding-bottom: 100px;

  .illustration-city {
    display: none;
    left: 0;
    position: absolute;
    top: 286px;
    transform: translateX(-50%);

    @include responsive(t-xs) {
      display: block;
    }
  }

  .header-login {
    .header-left {
      @include responsive(t) {
        display: block;
      }
    }

    @include responsive(t) {
      justify-content: space-between;
    }
  }

  .form-block {
    .switch {
      margin: 0 auto 32px;
    }
  }

  .form-validation-action {
    @include responsive(t-xs) {
      width: 160px;
    }
  }

  @include responsive(t-xs) {
    padding-bottom: 200px;
  }
}
