%icon-arrow {
  @include svg-bg('arrow_back');
  @include img-size(20px, 20px);
  display: inline-block;
}

.icon-arrow-left {
  @extend %icon-arrow;
}

.icon-arrow-right {
  @extend %icon-arrow;
  transform: rotate(180deg);
}

.icon-check {
  @include svg-bg('ic_check');
  @include img-size(24px, 24px);
  display: inline-block;
}

.icon-user {
  @include svg-bg('btn_account');
  @include img-size(32px, 32px);
  display: inline-block;
}

.icon-evol-neutral,
.icon-evol-pos,
.icon-evol-neg {
  @include img-size(15px, 15px);
  display: inline-block;
  vertical-align: middle;
}

.icon-evol-neutral {
  @include svg-bg('evol_neutral');
}

.icon-evol-pos {
   @include svg-bg('evol_pos');
 }

.icon-evol-neg {
  @include svg-bg('evol_neg');
}

.icon-options {
  cursor: pointer;
  height: 18px;
  width: 18px;

  span {
    @include circle(4px);
    background: $grey-darker;
    position: absolute;
    left: 7px;
    top: 50%;
    transform: translate(0, -50%);

    &:first-child {
      left: 0;
    }

    &:last-child {
      left: 14px;
    }
  }
}

.icon-circle-private,
.icon-circle-public {
  @include circle(14px);
  display: inline-block;
}

.icon-circle-private {
  background: $grey-darker;
}

.icon-circle-public {
  background: $primary-color;
}

.icon-warning {
  @include svg-bg('ic_alert_detail');
  @include img-size(16px, 16px);
  display: inline-block;
}

.icon-warning-white {
  @include svg-bg('ic_alert_blue');
  @include img-size(24px, 24px);
  display: inline-block;
}

.icon-arrow-selection {
  @include svg-bg('arrow_selection');
  @include img-size(10px, 6px);
  display: inline-block;
}

.icon-dialog {
  @include svg-bg('ic_dialog');
  @include img-size(22px, 20px);
  display: inline-block;
}

.icon-circle-plus {
  @include svg-bg('btn_add');
  @include img-size(48px, 48px);
  display: inline-block;
}

.icon-house {
  @include svg-bg('ic_roof');
}

.icon-sun {
  @include svg-bg('ic_light');
}

.icon-handicap {
  @include svg-bg('ic_pmr');
}

.icon-locker {
  @include svg-bg('ic_lock');
}

.icon-cam {
  @include svg-bg('ic_camera');
}

.icon-key {
  @include svg-bg('ic_groom');
}

.icon-card {
  @include svg-bg('ic_cb');
}

.icon-car {
  @include svg-bg('ic_car');
}

.icon-calendar {
  @include svg-bg('ic_calendar');
}

.icon-person {
  @include svg-bg('ic_person');
}

.icon-hotel {
  @include svg-bg('ic_hotel');
}

.icon-co-owner {
  @include svg-bg('ic_co_owner');
}

.icon-common {
  @include svg-bg('ic_common');
}

.icon-restaurant {
  @include svg-bg('ic_restaurant');
}

.icon-arrow-select {
  @include svg-bg('ic_dropdown');
  @include img-size(32px, 32px);
  display: inline-block;
}

.icon-close {
  @include circle(32px);
  background: $grey;
  display: inline-block;
  position: relative;
  transform: rotate(45deg);

  &::before,
  &::after {
    @include absolute-centered();
    background: $white;
    content: '';
    display: block;
  }

  &::before {
    height: 2px;
    width: 14px;
  }

  &::after {
    height: 2px;
    transform: translate(-50%, -50%) rotate(90deg);
    width: 14px;
  }
}

.icon-height {
  @include svg-bg('ic_height');
  @include img-size(48px, 48px);
  display: inline-block;
}

.icon-delete {
  display: inline-block;
  height: 8px;
  width: 8px;

  span {
    background: $white;
    height: 1px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 11px;

    &:first-child {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:last-child {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.icon-circle-add {
  @include circle(48px);
  background: $white;
  display: inline-block;
  transition: background .4s cubic-bezier(.85, .01, .58, 1);

  span {
    @include absolute-centered;
    background: $primary-color;
    height: 2px;
    width: 24px;
    transition: background .4s cubic-bezier(.85, .01, .58, 1);

    &:first-child {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}

.icon-circle-add-small {
  @include circle(24px);
  border: 2px solid $primary-color;
  cursor: pointer;
  display: inline-block;

  span {
    @include absolute-centered;
    background: $primary-color;
    height: 2px;
    width: 10px;

    &:first-child {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}

.icon-validate {
  @include svg-bg('ic_validate_blue');
  @include img-size(48px, 48px);
  display: inline-block;
}

.icon-cancel {
  @include svg-bg('ic_refuse_orange');
  @include img-size(48px, 48px);
  display: inline-block;
}

.icon-quote {
  @include svg-bg('ic_quote');
  @include img-size(44px, 38px);
  display: block;
  margin-bottom: 24px;
}

.icon-eye {
  @include img-size(32px, 32px);
  @include svg-bg('ic_password_hide');
}

.icon-instagram {
  @include img-size(48px, 48px);
  @include svg-bg('ic_instagram');
  display: inline-block;
}

.icon-facebook {
  @include img-size(48px, 48px);
  @include svg-bg('ic_facebook');
  display: inline-block;
}

.icon-twitter {
  @include img-size(48px, 48px);
  @include svg-bg('ic_twitter');
  display: inline-block;
}

.icon-bank {
  @include img-size(32px, 32px);
  @include svg-bg('ic_bank');
  display: inline-block;
}

.icon-search {
  @include img-size(32px, 32px);
  @include svg-bg('ic_search');
  display: inline-block;
}

.icon-chevron {
  @include img-size(48px, 48px);
  @include svg-bg('ic_chevron_small');
  display: inline-block;
}

.icon-download-small {
  @include img-size(20px, 20px);
  @include svg-bg('ic_download_small');
  display: inline-block;
}

.icon-info {
  @include img-size(20px, 20px);
  @include svg-bg('ic_info');
  display: inline-block;
}
