.form-block {
  $scope: &;
  @include clearfix();
  background: $white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $shadow;
  margin-bottom: 10px;
  padding: 16px;
  position: relative;

  &-intro {
    padding: 0 14px;

    @include responsive(t-xs) {
      padding: 0;
    }
  }

  &-title {
    @include font-family($main-font-heavy);
    @include font-size(12);
    align-items: center;
    color: $primary-color;
    display: flex;
    justify-content: flex-start;
    line-height: 1.6rem;
    letter-spacing: 1px;
    margin-bottom: 16px;
    position: relative;
    text-transform: uppercase;

    &-section {
      @include font-size(12);
      color: $primary-color;
      line-height: 1.8rem;
      letter-spacing: 0;
      margin-bottom: 16px;
    }

    .link-action {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .icon-warning {
      @include img-size(20px, 20px);
      display: none;
      margin-right: 8px;
      vertical-align: top;
    }

    .btn-small {
      @include font-size(10);
      letter-spacing: 0;
      line-height: 1.8rem;
      margin-left: auto;
      padding: 2px 8px;
    }

    @include responsive(t-xs) {
      line-height: 2.4rem;
    }
  }

  &-content {

  }

  &-text {
    @include font-size(12);
    color: $grey-darker;
    letter-spacing: 0;
    line-height: 1.2rem;
    margin-bottom: 16px;
  }

  &-info {
    align-items: baseline;
    display: flex;
    margin: -12px 0 16px;

    &-number {
      @include font-family($main-font-black);
      @include font-size(12);
      color: $grey-darker;
      display: inline-flex;
      line-height: 2.4rem;
      margin-right: 4px;
    }

    &-label {
      @include font-size(14);
      color: $grey-darker;
      display: inline-flex;
      line-height: 2.1rem;
    }
  }

  .checkbox-right {
    float: right;
    margin-bottom: 32px;
  }

  &-permission {
    @include clearfix();

    .btn-fixed {
      max-width: 116px;
      padding: 6px 0;
    }

    .btn-primary {
      float: right;
      margin-top: 6px;
    }

    .form-input {
      float: left;
      max-width: calc(100% - 136px);
    }
  }

  &-transfer {
    .checkbox {
      margin-bottom: 24px;
    }

    .form-input,
    .btn-fixed {
      display: inline-block;
      margin-bottom: 16px;
      vertical-align: middle;
    }

    .form-input {
      @include responsive(t-xs) {
        margin-right: 16px;
        max-width: calc(100% - 216px);
      }
    }

    .btn-fixed {
      width: 100%;

      @include responsive(t-xs) {
        max-width: 196px;
      }
    }
  }

  &-warning {
    #{$scope} {
      &-title {
        color: $secondary-color;

        .icon-warning {
          display: inline-block;
        }
      }
    }
  }

  .icon-circle-add-small {
    position: absolute;
    right: 24px;
    top: 12px;
    z-index: map_get($zindex, lvl0-1);

    @include responsive(t-xs) {
      top: 16px;
    }
  }

  &-no-editable {
    .on-focus {
      .form-input-field {
        pointer-events: none;
      }

      .form-input-label {
        &::after {
          transform: translate(-100%, 0);
        }
      }
    }
  }

  &-danger {
    #{$scope} {
      &-title {
        color: $negative;
      }
    }
  }

  &-scheduler {
    position: relative;
  }

  &-section {
    &-title {
      .form-input {
        @include responsive(t-xs) {
          flex: 0 1 60%;
        }

        @include responsive(t) {
          flex: 0 1 50%;
        }
      }

      @include responsive(t-xs) {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 72px 0 24px;
      }
    }
  }

  @include responsive(t-xs) {
    margin-bottom: 20px;
    padding: 16px 24px;
  }
}
