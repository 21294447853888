.creation {
  padding-bottom: 100px;

  .illustration-city {
    display: none;
    left: 0;
    position: absolute;
    top: 286px;
    transform: translateX(-50%);

    @include responsive(t-xs) {
      display: block;
    }
  }

  .header {
    &-left {
      .link {
        display: none;

        @include responsive(t) {
          display: inline-block;
        }
      }

      .logo-main {
        @include responsive(t) {
          display: none;
        }
      }
    }

    &-center {
      display: none;

      @include responsive(t) {
        display: block;
      }
    }
  }

  .form-validation-action {
    @include responsive(t-xs) {
      width: 50%;
    }
  }

  @include responsive(t-xs) {
    padding-bottom: 280px;
  }
}
