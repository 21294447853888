.list-table {
  &-header {
    margin-bottom: 8px;
    padding: 0 24px;

    &-title {
      @include font-size(12);
      color: $grey;
      cursor: pointer;
      display: inline-block;
      letter-spacing: 1px;
      line-height: 1.4rem;
      margin-right: 8px;
      text-transform: uppercase;
      vertical-align: middle;

      &-info {
        width: 38%;
      }

      &-mode,
      &-availability {
        display: none;
        width: 24%;
      }

      .icon-arrow-selection {
        display: none;
        margin-left: 8px;
        vertical-align: middle;
      }

      &.active {
        @include font-family($main-font-bold);

        .icon-arrow-selection {
          display: inline-block;
        }
      }
    }
  }
}
