.dashboard-card {
  $scope: &;
  @include clearfix();
  background: $white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $shadow;
  margin-bottom: 20px;
  overflow: auto;
  padding: 16px 24px 0 24px;

  &-title {
    @include font-family($main-font-heavy);
    @include font-size(12);
    color: $primary-color;
    line-height: 1.4rem;
    letter-spacing: 1px;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  &-subtitle {
    @include font-size(12);
    color: $grey;
    line-height: 1.6rem;
    margin-bottom: 20px;
  }

  &-main {
    padding: 29px 24px 16px 24px;

    #{$scope} {
      &-title {
        @include font-family($main-font-heavy);
        @include font-size(24);
        color: $grey-darker;
        letter-spacing: 0;
        line-height: 3.2rem;
        margin-bottom: 0;
        text-transform: inherit;

        @include responsive(s-xl) {
          margin-bottom: 16px;
          margin-left: 38%;
          max-width: 62%;
          order: 2;
        }
      }

      &-stats {
        margin: 0 auto 20px;
        max-width: 75%;

        @include responsive(s-xl) {
          left: 16px;
          margin: 0;
          max-width: 35%;
          order: 1;
          position: absolute;
          top: 0;
          width: 224px;
        }
      }

      &-content {
        @include responsive(s-xl) {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    .list-legend {
      @include responsive(s-xl) {
        margin-left: 38%;
        max-width: 62%;
        order: 3;
      }
    }

    @include responsive(s-xl) {
      padding: 24px 24px 22px 24px;
    }
  }

  &-activation {
    padding: 32px 24px 44px 24px;
    text-align: center;

    #{$scope} {
      &-title {
        @include font-size(32);
        color: $grey-darker;
        letter-spacing: 0;
        line-height: 5.6rem;
        margin-bottom: 24px;
        text-transform: inherit;

        .text-highlight {
          @include font-family($main-font-black);
          @include font-size(48);
          color: $primary-color;
          display: block;
        }
      }
    }

    .btn-fixed {
      width: 160px;
    }
  }
}
